(function () {
    'use-strict';

    angular.module('jkuri.gallery', []).directive('ngGallery', ngGallery);

    ngGallery.$inject = ['$document', '$timeout', '$q', '$templateCache'];

    function ngGallery($document, $timeout, $q, $templateCache) {

        var defaults = {
            baseClass: 'ng-gallery',
            thumbClass: 'ng-thumb',
            templateUrl: 'ng-gallery.html'
        };

        var keys_codes = {
            enter: 13,
            esc: 27,
            left: 37,
            right: 39
        };

        function setScopeValues(scope, attrs) {
            scope.baseClass = scope.class || defaults.baseClass;
            scope.thumbClass = scope.thumbClass || defaults.thumbClass;
            scope.thumbsNum = scope.thumbsNum || 3; // should be odd
        }

        var template_url = defaults.templateUrl;
        var defaultImage = '\/app\/images\/default.jpg';
        // Set the default template
        $templateCache.put(template_url,

            //
            //
            //'<div class="{{ baseClass }}">' +
            //'  <div class="col-md-2 col-sm-3 col-xs-6 pr-ng-gal-thumbnail-holder" ng-repeat="i in images">' +
            //'    <img  onerror="this.src=\'app/images/default.jpg\'"  ng-src="{{ i.thumb }}" class="{{ thumbClass }}" ng-click="openGallery($index)" alt="Image {{ $index + 1 }}" />' +
            //'  </div>' +
            //'</div>' +
            //'<div class="ng-overlay" ng-show="opened">' +
            //'</div>' +
            //'<div class="ng-gallery-content" unselectable="on" ng-show="opened" ng-swipe-left="nextImage()" ng-swipe-right="prevImage()">' +
            //'  <div class="uil-ring-css" ng-show="loading"><div class="uil-ring-css-inner"><div class="circle"></div></div> </div>' +
            //'<a href="{{getImageDownloadSrc()}}" target="_blank" ng-show="showImageDownloadButton()" class="download-image"><i class="fa fa-download"></i></a>' +
            //'  <a class="close-popup" ng-click="closeGallery()"><i class="fa fa-close"></i></a>' +
            //'  <a class="nav-left" ng-click="prevImage()"><i class="fa fa-angle-left"></i></a>' +
            //'  <img onerror="this.src=\'app/images/default.jpg\'"  ondragstart="return false;" draggable="false" ng-src="{{ img }}" ng-click="nextImage()" ng-show="!loading" class="effect" />' +
            //'  <a class="nav-right" ng-click="nextImage()"><i class="fa fa-angle-right"></i></a>' +
            //'</div>'

            '<div class="{{ baseClass }}">' +
            '  <div class="pr-ng-gal-thumbnail-holder-bg" ng-class="{\'ng-gallery-holder-couple\': (images.length == 2), \'ng-gallery-holder-quad\': (images.length >= 4), \'ng-gallery-holder-three\': (images.length == 3), \'ng-gallery-holder-three-por\': (images.length == 3 && $index == 0 && i.orientation == 1), \'ng-gallery-holder-three-land\': (images.length == 3 && $index == 0 && i.orientation == 0), \'ng-gallery-hide-extra\': ($index > 3)}" ng-repeat="i in images">' +
            '    <img ng-click="openGallery($index)" ng-if="images.length == 1" src="{{i.thumb}}" onerror="this.src=\'assets/app/images/no-event-pic.png\'" />' +
            '    <div ng-if="images.length == 3 && $index == 0" class="pr-ng-gal-thumbnail-holder" ng-click="openGallery($index)" background-image-directive="{{i.rectangle}}" style="background-color: gray; background-position: center center; background-size: cover;"></div>' +
            '    <div ng-if="(images.length >= 2 && images.length == 3 && $index > 0) || (images.length >= 2 && images.length != 3)" class="pr-ng-gal-thumbnail-holder" ng-click="openGallery($index)" background-image-directive="{{i.square}}" style="background-color: gray; background-position: center center; background-size: cover;">' +
            '      <div ng-if="(images.length > 4) && ($index == 3)" class="ng-gallery-extra"><span>+{{ ((images.length) - ($index + 1)) }}</span></div>' +
            '    </div>' +
            '  </div>' +
            '</div>' +
            '<div class="ng-overlay" ng-show="opened">' +
            '</div>' +
            '<div class="ng-gallery-content" unselectable="on" ng-show="opened" ng-swipe-left="nextImage()" ng-swipe-right="prevImage()">' +
            '  <div class="uil-ring-css" ng-show="loading"><div class="uil-ring-css-inner"><div class="circle"></div></div> </div>' +
            //'<a href="{{getImageDownloadSrc()}}" target="_blank" ng-show="showImageDownloadButton()" class="download-image"><i class="fa fa-download"></i></a>' +
            '  <a class="close-popup" ng-click="closeGallery()"><i class="fa fa-close"></i></a>' +
            '  <a ng-hide="images.length == 1" class="nav-left" ng-click="prevImage()"><i class="fa fa-angle-left"></i></a>' +
            '  <img onerror="this.src=\'assets/app/images/no-event-pic.png\'"  ondragstart="return false;" draggable="false" ng-src="{{ img }}" ng-click="nextImage()" ng-show="!loading" class="effect" />' +
            '  <a ng-hide="images.length == 1" class="nav-right" ng-click="nextImage()"><i class="fa fa-angle-right"></i></a>' +
            '</div>'


        );

        return {
            restrict: 'EA',
            scope: {
                images: '=',
                thumbsNum: '@',
                hideOverflow: '='
            },
            controller: [
                '$scope',
                function ($scope) {
                    $scope.$on('openGallery', function (e, args) {
                        $scope.openGallery(args.index);
                    });
                }
            ],
            templateUrl: function (element, attrs) {
                return attrs.templateUrl || defaults.templateUrl;
            },
            link: function (scope, element, attrs) {
                setScopeValues(scope, attrs);

                if (scope.thumbsNum >= 11) {
                    scope.thumbsNum = 11;
                }

                var $body = $document.find('body');
                var $thumbwrapper = angular.element(element[0].querySelectorAll('.ng-thumbnails-wrapper'));
                var $thumbnails = angular.element(element[0].querySelectorAll('.ng-thumbnails'));

                scope.index = 0;
                scope.opened = false;

                scope.thumb_wrapper_width = 0;
                scope.thumbs_width = 0;

                var loadImage = function (i) {
                    var deferred = $q.defer();
                    var image = new Image();

                    image.onload = function () {
                        scope.loading = false;
                        if (typeof this.complete === false || this.naturalWidth === 0) {
                            deferred.reject();
                        }
                        deferred.resolve(image);
                    };

                    image.onerror = function () {
                        deferred.reject();
                    };

                    image.src = scope.images[i].img;
                    scope.loading = true;

                    return deferred.promise;
                };

                var showImage = function (i) {
                    loadImage(scope.index).then(function (resp) {
                        scope.img = resp.src;
                        smartScroll(scope.index);
                    });
                    scope.description = scope.images[i].description || '';
                };

                scope.showImageDownloadButton = function () {
                    if (scope.images[scope.index] == null || scope.images[scope.index].downloadSrc == null) return
                    var image = scope.images[scope.index];
                    return angular.isDefined(image.downloadSrc) && 0 < image.downloadSrc.length;
                };

                scope.getImageDownloadSrc = function () {
                    if (scope.images[scope.index] == null || scope.images[scope.index].downloadSrc == null) return
                    return scope.images[scope.index].downloadSrc;
                };

                scope.changeImage = function (i) {
                    scope.index = i;
                    showImage(i);
                };

                scope.nextImage = function () {
                    scope.index += 1;
                    if (scope.index === scope.images.length) {
                        scope.index = 0;
                    }
                    showImage(scope.index);
                };

                scope.prevImage = function () {
                    scope.index -= 1;
                    if (scope.index < 0) {
                        scope.index = scope.images.length - 1;
                    }
                    showImage(scope.index);
                };

                scope.openGallery = function (i) {
                    $('body, html').addClass('pr-overflow-hide');

                    if(isMobile == true) {
                        $('body').addClass('gallery-open');
                        var height = $("#jt-webapp-stage").height();
                        $("#jt-webapp-stage").css({height: (height + 150) + 'px'});
                    }

                    if (typeof i !== undefined) {
                        scope.index = i;
                        showImage(scope.index);
                    }
                    scope.opened = true;
                    if (scope.hideOverflow) {
                        $('body').css({overflow: 'hidden'});
                    }

                    $timeout(function () {
                        var calculatedWidth = calculateThumbsWidth();
                        scope.thumbs_width = calculatedWidth.width;
                        //Add 1px, otherwise some browsers move the last image into a new line
                        var thumbnailsWidth = calculatedWidth.width + 1;
                        $thumbnails.css({width: thumbnailsWidth + 'px'});
                        $thumbwrapper.css({width: calculatedWidth.visible_width + 'px'});
                        smartScroll(scope.index);
                    });
                };

                scope.closeGallery = function () {
                    scope.opened = false;
                    $('body, html').removeClass('pr-overflow-hide');

                    if(isMobile == true) {
                        $('body').removeClass('gallery-open');
                        var height = $("#jt-webapp-stage").height();
                        $("#jt-webapp-stage").css({height: (height - 150) + 'px'});
                    }
                    if (scope.hideOverflow) {
                        $('body').css({overflow: ''});
                    }
                };

                $body.bind('keydown', function (event) {
                    if (!scope.opened) {
                        return;
                    }
                    var which = event.which;
                    if (which === keys_codes.esc) {
                        scope.closeGallery();
                    } else if (which === keys_codes.right || which === keys_codes.enter) {
                        scope.nextImage();
                    } else if (which === keys_codes.left) {
                        scope.prevImage();
                    }

                    scope.$apply();
                });

                var calculateThumbsWidth = function () {
                    var width = 0,
                        visible_width = 0;
                    angular.forEach($thumbnails.find('img'), function (thumb) {
                        width += thumb.clientWidth;
                        width += 10; // margin-right
                        visible_width = thumb.clientWidth + 10;
                    });
                    return {
                        width: width,
                        visible_width: visible_width * scope.thumbsNum
                    };
                };

                var smartScroll = function (index) {
                    $timeout(function () {
                        var len = scope.images.length,
                            width = scope.thumbs_width,
                            item_scroll = parseInt(width / len, 10),
                            i = index + 1,
                            s = Math.ceil(len / i);

                        //$thumbwrapper[0].scrollLeft = 0;
                        //$thumbwrapper[0].scrollLeft = i * item_scroll - (s * item_scroll);
                    }, 100);
                };

            }
        };
    }
})();
