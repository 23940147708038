'use strict';

(function (window, angular, undefined) {
    angular.module('angularYoutubePreview', []).directive('youtubePreview', [function () {
        return {
            restrict: 'E',
            replace: true,
            template: '<div></div>',
            scope: {
                v: '@',
                source: '@',
                thumbnail: '@',
                frameKey: '@',
                playIcon: '@',
                width: '@',
                height: '@'
            },
            link: function (scope, element, attrs) {
                var preview, playImage = angular.element('<img>');

                if ( ! scope.frameKey) {
                    scope.frameKey = 0;
                }

                //preview = '//img.youtube.com/vi/' + scope.v + '/' + scope.frameKey + '.jpg';
                preview = scope.thumbnail;

                element.css({
                    width: scope.width + 'px',
                    height: scope.height + 'px',
                    position: 'relative',
                    background: 'url(' + preview + ') no-repeat center',
                    backgroundSize: 'cover',
                    cursor: 'pointer'
                });

                if (scope.playIcon) {
                    playImage.attr({
                        alt: 'play',
                        title: 'Play',
                        src: scope.playIcon,
                        class: 'jt-post-video-play-icon'
                    });

                    playImage.css({
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80px',
                        border: 'none'
                    });

                    element.append(playImage);
                }

                element.bind('click', function () {
                    var iframe = angular.element('<iframe></iframe>').attr({
                        width: '100%',
                        height: '100%',
                        frameborder: 0,
                        allowfullscreen: '',
                        src: (scope.source === 'youtube') ? "//www.youtube.com/embed/" + scope.v + "?rel=0&autoplay=1"
                            : "https://player.vimeo.com/video/" + scope.v + "?autoplay=1"
                    });

                    element.empty();
                    element.append(iframe);
                });
            }
        }
    }]);
})(window, angular);
